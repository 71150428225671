@import "styles/variables.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

  & > h1 {
    font-size: 32px;
    line-height: 60px;
    font-weight: 500;
  }

  & > h2 {
    font-size: 16px;
    line-height: 60px;
    font-weight: normal;
  }
}

.status {
  padding-right: 20px;
  border-right: 1px solid black;
}
